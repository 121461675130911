import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../common/routes';
import { Auth } from '../../services/auth/login';
import { AuthContext } from '../../contexts/authentication';

// This was heavily refactored from a method and transformed to a hook.
// Prior to this refactor a method was called based on auth state,
//  auth state was always unset/ undefined as it was initialized after the method was invoked.
// We needed to know if you are in the ruby app so we can sign out the ruby way.

export const useAuthInterceptor = (
  auth: Auth,
  legacySignOut: AuthContext['signOut'],
) => {
  const navigate = useNavigate();
  useEffect(() => {
    const originalFetch = window.fetch;

    // Only override window.fetch if it's not already overridden (to avoid multiple overrides)
    if (window.fetch !== originalFetch) return;

    window.fetch = async (...args) => {
      let [resource, config] = args;

      const isNextAuthRequest = resource.toString().includes(`/api/auth`);
      if (isNextAuthRequest) {
        const isSignOut = resource.toString().includes(`/api/auth/signout`);
        config = {
          method: isSignOut ? 'POST' : 'GET',
          credentials: 'include',
        };
      }

      let response = await originalFetch(resource, config);

      const isMadeToBFF =
        response.url.startsWith(API_HOST) || response.url.startsWith(NEXT_HOST);
      const isLogin = response.url.includes('/api/v1/auth');
      const isRegister = response.url.includes('/api/v1/register');
      const isBuyCredits = response.url.includes('/rgs-proxy/api/v1/orders');

      if (!isMadeToBFF || isLogin || isRegister || isBuyCredits)
        return response;

      if (response.status === 401 || response.status === 403) {
        if (auth.session !== undefined) {
          if (auth?.rails) {
            window.location.href = '/en/logout/';
          } else {
            navigate(`/en/logout/sign-out`);
          }
        } else {
          legacySignOut(Routes.Login).then();
        }
        return Promise.reject(response);
      }

      return response;
    };

    // Cleanup: Reset window.fetch back to the original after the component unmounts or auth.session changes
    return () => {
      window.fetch = originalFetch;
    };
  }, [auth?.rails, auth.session, legacySignOut, navigate]);
};
