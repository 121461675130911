import { Root, Trigger, Arrow, Content, Portal } from '@radix-ui/react-popover';
import './styles.css';
import { FeatureFlagsService } from '../../../../../../../services/feature-flags/feature-flags';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../../../../contexts/authentication';
import { useGameProducts } from '../../../../../../../hooks/use-get-game-products';
import {
  fetchGameProducts,
  GameProduct,
} from '../../../../../../../services/get-game-products/get-game-products';
import { useToaster } from 'contexts/toaster-provider/toaster-provider';
import { usePlayerStatus } from '../../../../../../../hooks/use-player-status/user-player-status';
import { useGetCredits } from '../../../../../../../hooks/use-credits/use-credits';
export const OneTimeSetupPopover = () => {
  const { data } = useGameProducts();
  const [showState, setShowState] = useState<'hidden' | 'active' | 'in-active'>(
    'hidden',
  );
  const { data: playerStatus } = usePlayerStatus();
  const { auth } = useAuth();
  const intervalRef = useRef(null);
  const { data: credits } = useGetCredits();

  const { pushToast, removeToast } = useToaster();

  // if we are in an instant state + we do not have immediate on all packages + the account is less than an hour old, we need to show the coin.
  useEffect(() => {
    if (
      data?.gameProducts?.length > 0 &&
      auth?.session?.state &&
      playerStatus?.validator_status === 1 &&
      FeatureFlagsService?.featureFlags?.enableOnboardingPage &&
      credits?.created_at
    ) {
      const { activeInstantPlayReserveStates } =
        FeatureFlagsService.featureFlags;
      const isAccountLessThan120Minuets =
        checkAccountSetupMinuets(credits.created_at) < 120;
      const isAccountSetupComplete = checkGameProductsAreImmediate(
        data.gameProducts,
      );
      const isInPremiumState = activeInstantPlayReserveStates.includes(
        auth.session.state,
      );

      const show =
        isAccountLessThan120Minuets &&
        !isAccountSetupComplete &&
        isInPremiumState;
      setShowState(show ? 'active' : 'hidden');
    }
  }, [
    auth?.session.state,
    credits?.created_at,
    data?.gameProducts,
    playerStatus?.validator_status,
  ]);

  // if the coin is showing we need to start polling every 30 seconds.
  // if the coin was showing and now all packages are immediate we need to remove the coin and show the toast.
  useEffect(() => {
    const checkAccountSetup = async () => {
      try {
        const data = await fetchGameProducts(auth.session);
        return data?.gameProducts
          .filter((gameProduct) => gameProduct.cost !== 250)
          .every((gameProduct) => gameProduct.expected_wait === 'IMMEDIATE');
      } catch (error) {
        setShowState('hidden');
      }
    };
    if (showState === 'active') {
      intervalRef.current = setInterval(async () => {
        const isAccountSetupComplete = await checkAccountSetup();
        if (isAccountSetupComplete) {
          clearInterval(intervalRef.current);
          setShowState('in-active');
        }
      }, 30000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [auth?.session, showState]);
  const animationEndHandler = (event) => {
    if (event.animationName.includes('shrink')) {
      const completeToast = pushToast({
        message: 'Your account is 100% ready to go!',
        icon: (
          <img
            src='/js_assets/images/alert-icon-2.png'
            alt='account success'
            width={50}
            height={41}
          />
        ),
        type: 'success',
        closeHandler: () => removeToast(completeToast),
        // style: { backgroundColor: 'rgba(255, 255, 255, 0.85)' },
      });
      setShowState('hidden');
    }
  };

  return (
    showState !== 'hidden' && (
      <Root>
        <Trigger
          style={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            borderStyle: 'unset',
            padding: '0',
            background: 'unset',
          }}
        >
          <div
            className={[
              'oneTimeSetupPopoverTrigger',
              showState === 'active' ? 'inAnimation' : '',
              showState === 'in-active' ? 'outAnimation' : '',
            ].join(' ')}
            onAnimationEnd={animationEndHandler}
          >
            <img
              className='oneTimeSetupPopoverImage'
              src='/js_assets/icons/loading42x42.gif'
              alt='loading coin'
            />
          </div>
        </Trigger>
        <Portal>
          <Content
            side={'top'}
            align='center'
            sideOffset={4}
            className='text-black'
            style={{
              maxWidth: '288px',
              background: '#FFFFFF',
              marginRight: '8px',
              borderRadius: '8px',
              zIndex: 1,
              padding: '0.75rem',
            }}
          >
            <p style={{ fontSize: '12px' }}>
              We're putting the finishing touches on your account. It should
              take about 20 minutes.
            </p>

            <Arrow style={{ fill: '#FFFFFF' }} width={20} height={10} />
          </Content>
        </Portal>
      </Root>
    )
  );
};

const checkGameProductsAreImmediate = (gameProducts: GameProduct[]): boolean =>
  gameProducts
    .filter((gameProduct: GameProduct) => gameProduct.cost !== 250)
    .every(
      (gameProduct: GameProduct) => gameProduct.expected_wait === 'IMMEDIATE',
    );

const checkAccountSetupMinuets = (date: string): number => {
  const createdDate = new Date(date);
  const dateNow = new Date();
  const differenceInMs = dateNow.getTime() - createdDate.getTime();
  return +(differenceInMs / (1000 * 60)).toFixed(2);
};
