import { Api } from 'common/api';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const LoginUnexpectedErrorMessage = 'ERROR_CODE:500';

export type LoginCredentials = {
  email: string;
  password: string;
};

export type User = {
  playerId: number;
  companyId: number;
  pointId: number;
  login: string;
  email: string;
  barcode: string;
  balance: string;
  countryId: number;
  currencyId: number;
  fromWeb: boolean;
  isTest: boolean;
  disableOneClickBet: boolean;
  disableCashout: boolean;
  playerLoginRes: string;
  gambleTimeLimit: number;
  gambleTimeLimits: number[];
  changeLogin: boolean;
  personId: number;
  trustlyDepositStatus: number;
  showDepositLimit: boolean;
  canPlay: number;
  responsibleGamingInfo: string;
  code: number;
  message: string;
  currency: string;
  tnc_required: boolean;
  token: string;
  auth_key: string;
  last_login_at: string;
  refresh_token: string;
  total_deposit_count: number;
  reality_check_period: number;
  deposit_threshold_amount: number;
};

export type Profile = {
  sessionId: number;
  playerSessionToken: string;
  playerId: number;
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  state: string;
  resultCode: number;
};

export type CompLabsMember = {
  jsonClass: string;
  accountId: string;
  memberType: string;
  name: string;
  memberRefId: string;
  groups: string[];
  metadata: [];
  created: string;
  id: string;
};

export type Auth = {
  auth: User;
  session: Profile;
  token: string;
  segments?: string[] | null;
  rails?: boolean;
};

export const login = async (payload: LoginCredentials): Promise<Auth> => {
  const url = FeatureFlagsService.featureFlags?.enableCloudfare
    ? `${API_HOST}/api/v1/auth2`
    : `${API_HOST}/api/v1/auth`;

  const { status, data } = await Api.post<Auth>(url, {
    data: payload,
  });
  if (status === 500) throw new Error(LoginUnexpectedErrorMessage);

  if (status !== 200) {
    // @ts-ignore
    throw new Error(
      data?.message?.Message
        ? data?.message?.Message
        : data.message || 'Unexpected error',
    );
  }
  const jwtDataPart = data.token.split('.')[1];
  const jwtData: Auth = JSON.parse(atob(jwtDataPart));
  // rgs is session valid
  const auth = {
    token: data.token,
    ...jwtData,
  };

  return auth;
};

export type KycStatus = { attempts: number; max_attempts: number };

export type UserStatus = {
  token: string;
  id: number;
  name: string;
  balance: string;
  barcode: string;
  currency: string;
  email: string;
  first_name: string;
  last_name: string;
  validator_status: number;
  player_timeout: string;
  tnc_required: boolean;
  authentication_enabled: boolean;
  last_login_at: string;
  total_deposit_count: number;
  reality_check_period: number;
};

export const getUserStatus = async (): Promise<UserStatus> => {
  const { ok, data } = await Api.get<StriveResponse<UserStatus>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/status?d=${new Date().toString()}`,
  );

  if (!ok || !data.Success) {
    throw new Error('error getting status');
  }
  return data.Data;
};
