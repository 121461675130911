import { useAuth } from 'contexts/authentication';
import { useEffect, useRef } from 'react';
import io from 'socket.io-client-latest';
import { useGetBalance } from 'hooks/use-balance';
import { useKyc } from 'contexts/kyc/kyc-context';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { useKycStatus } from 'hooks/use-kyc-status';
import { useProfileInfo } from 'hooks/use-profile-info';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { useNavigate } from 'react-router-dom';

export enum StriveMessageAction {
  PLAYER_DISABLED = 'player_disabled',
  BONUS_WALLET_CHANGED = 'bonus_wallet_changed',
  BALANCE_CHANGED = 'balance_changed',
  BANK_ACCOUNT_CHANGED = 'bank_account_changed',
  AUTH_INFO = 'auth_info',
  STATUS_CHANGED = 'status_changed',
  SESSION_ENDED = 'session_ended',
}

type StriveMessage = {
  action: StriveMessageAction;
  data: any;
};

type StriveIoOptions = {
  transports: string[];
  path?: string;
};

export const useStriveSocketListener = () => {
  const { auth, signOut } = useAuth();
  const navigate = useNavigate();
  const { setShowDisabled } = useKyc();
  const { refetch: refetchBalances } = useGetBalance();
  const { refetch: refetchStatus, data: status } = usePlayerStatus();
  const { refetch: refetchKYC } = useKycStatus();
  const { refetch: refetchProfile } = useProfileInfo();
  const socket = useRef<ReturnType<typeof io> | null>(null);
  let disabled = false;

  useEffect(() => {
    if (!status?.token) {
      if (socket.current) disconnect();
      return;
    }

    connect();

    return () => {
      disconnect();
    };
  }, [status, connect, disconnect]);

  function onConnect() {
    socket.current.emit('join', status.token);

    if (FeatureFlagsService?.featureFlags?.environment !== 'prod')
      console.log('Connected to Strive socket');
  }

  function connect() {
    let options: StriveIoOptions = {
      transports: ['websocket'],
    };
    // if (FeatureFlagsService?.featureFlags?.environment !== 'stage') {
    //   options = {
    //     ...options,
    //     path: '/ws/',
    //   };
    // }
    socket.current = io(
      FeatureFlagsService?.featureFlags?.striveSocketHref,
      options,
    );
    socket.current.connect();
    socket.current.on('connect', onConnect);
    socket.current.on('message', onMessage);
  }

  function disconnect() {
    socket.current.off('connect', onConnect);
    socket.current.off('message', onMessage);
    socket.current.disconnect();
  }

  async function onMessage(message: StriveMessage) {
    if (FeatureFlagsService?.featureFlags?.environment !== 'prod')
      console.log('Strive socket message received', message);

    if (message.action === StriveMessageAction.PLAYER_DISABLED) {
      if (ENABLE_NEXT_AUTH === 'true') {
        if (auth?.rails) {
          window.location.href = '/en/logout';
        } else {
          navigate('/en/logout/sign-out');
        }
      } else {
        disabled = true;
        setShowDisabled(true);
        await signOut();
      }
      disconnect();
      return;
    }

    switch (message.action) {
      case StriveMessageAction.SESSION_ENDED:
        if (ENABLE_NEXT_AUTH === 'true') {
          if (auth?.rails) {
            window.location.href = '/en/logout';
          } else {
            navigate('/en/logout/sign-out');
          }
        } else {
          await signOut();
        }
        break;
      case StriveMessageAction.BALANCE_CHANGED:
        refetchBalances();
        break;
      case StriveMessageAction.STATUS_CHANGED:
        if (!disabled) {
          refetchStatus();
          refetchKYC();
          refetchProfile();
        }
        break;
    }
  }

  return socket.current;
};
