import { Header, WagerItem } from 'pages/account-history/styled';
import { Text } from 'components/atoms/text';
import {
  TransactionType,
  TransactionUI,
} from 'services/transactions/transactions';
import SeeWagerDetails from 'components/organisms/see-wager-details/see-wager-details';
import styled from '@emotion/styled';

interface twsProps {
  zeroRecords?: boolean;
}

export const TableWithScroll = styled.div<twsProps>`
  min-height: 1200px;
  border-radius: 10px;
  border: ${(props) =>
    props.zeroRecords ? 'none' : '0.5px solid rgba(68, 68, 68, 1)'};
`;

export type TransactionsListProps = {
  period: number;
  userTransactions: TransactionUI[];
};

const resolveGameWin = (amount) => {
  const am = amount.split('$ ')[1];
  return parseFloat(am.replace(/,/g, '')) > 0;
};

const transactionTypeDisplays: Record<TransactionType, string> = {
  [TransactionType.PACKAGE_REFUND]: 'Package Refund',
  [TransactionType.CREDITS_PURCHASED]: 'Credits Purchased',
  [TransactionType.WITHDRAW]: 'Withdrawal',
  [TransactionType.WITHDRAW_REJECTED]: 'Withdrawal Rejected',
  [TransactionType.WITHDRAW_REJECTED_NEW]: 'Withdrawal Rejected',
  [TransactionType.WITHDRAW_CORRECTION]: 'Withdrawal Correction',
  [TransactionType.DEPOSIT]: 'Deposit',
  [TransactionType.GAME_WIN]: 'Game',
  [TransactionType.GAME_PLAYED]: 'Game',
  [TransactionType.FREE_CREDITS]: 'Bonus Money',
  [TransactionType.DEPOSIT_CORRECTION]: 'Deposit Correction',
  [TransactionType.DEPOSIT_RETURNED]: 'Deposit Returned',
  [TransactionType.DEPOSIT_CONFIRMED_MANUALLY]: 'Deposit Confirmed Manually',
  [TransactionType.FREEPLAY]: 'Bonus Credits',
  [TransactionType.TYPE_21]: '',
  [TransactionType.INACTIVE_FEE]: 'Inactive Fee',
};

export function TransactionsList({
  period,
  userTransactions,
}: TransactionsListProps) {
  const resolveRowType = (transaction) => {
    //Balance Correction, Withdraw Correction, Freeplay, Package Refund
    const singleLine = [
      TransactionType.FREEPLAY,
      TransactionType.FREE_CREDITS,
      TransactionType.DEPOSIT_CORRECTION,
      TransactionType.DEPOSIT_RETURNED,
      TransactionType.DEPOSIT_CONFIRMED_MANUALLY,
      TransactionType.PACKAGE_REFUND,
      TransactionType.WITHDRAW_CORRECTION,
      TransactionType.WITHDRAW_REJECTED,
      TransactionType.WITHDRAW_REJECTED_NEW,
      TransactionType.INACTIVE_FEE,
    ];

    return singleLine.includes(transaction.title);
  };

  return (
    <TableWithScroll zeroRecords={userTransactions?.length === 0}>
      <div className='py-3 d-flex px-2'>
        {userTransactions?.length > 0 ? (
          <strong>Showing data for the last {period} days. </strong>
        ) : (
          <strong>No transactions available. </strong>
        )}
      </div>
      <div>
        {!userTransactions?.length ? (
          <p className='py-5'></p>
        ) : (
          userTransactions.map((transaction) => (
            <div key={transaction.time}>
              <Header>
                <Text>{transaction.date}</Text>
                <Text>{transaction.time}</Text>
              </Header>
              {transaction.transactions.map((item, i) => (
                <WagerItem key={i}>
                  {resolveRowType(item) ? (
                    <>
                      <div className={'fw-bold'}>
                        {transactionTypeDisplays[item.title]}
                      </div>
                      <div className={'fw-bold'}></div>
                      <div
                        className={'fw-bold'}
                        style={{
                          color:
                            item.title === TransactionType.FREEPLAY ||
                            (item.title === TransactionType.FREE_CREDITS &&
                              +item.amount.split('$ ')[1] > 0)
                              ? '#10846F'
                              : '',
                        }}
                      >
                        {item.in || item.title === TransactionType.FREEPLAY
                          ? '+'
                          : '-'}
                        {item.amount}
                      </div>
                    </>
                  ) : (
                    <>
                      {item.title === TransactionType.GAME_PLAYED && (
                        <>
                          <span className='fw-bold'>{item.game_name}</span>
                          <span className='fw-bold'>Played</span>
                          <span className='fw-bold'>
                            {item.amount.replace(' ', '')}
                          </span>
                        </>
                      )}
                      {item.title === TransactionType.GAME_WIN && (
                        <>
                          <span className='fw-bold'>{item.game_name}</span>
                          <span className='fw-bold'>Won</span>
                          <span
                            className='fw-bold'
                            style={{
                              color: resolveGameWin(item.amount)
                                ? '#10846F'
                                : 'white',
                            }}
                          >
                            {item.amount.replace(' ', '')}
                          </span>
                        </>
                      )}

                      {![
                        TransactionType.GAME_PLAYED,
                        TransactionType.GAME_WIN,
                      ].includes(item.title) && (
                        <>
                          <span className='fw-bold'>
                            {transactionTypeDisplays[item.title]}
                          </span>
                          {item.title === TransactionType.CREDITS_PURCHASED && (
                            <SeeWagerDetails
                              id={item.transaction_id.toString()}
                            />
                          )}
                          {[
                            TransactionType.DEPOSIT,
                            TransactionType.WITHDRAW,
                          ].includes(item.title) && (
                            <span style={{ fontSize: '14px' }}>
                              Account {item.account_number}
                            </span>
                          )}
                          <span
                            className={'fw-bold'}
                            style={{
                              color:
                                item.title === TransactionType.DEPOSIT
                                  ? '#10846F'
                                  : '#FFFFFF',
                            }}
                          >
                            {item.in ? '+' : '-'}
                            {item.amount.replace(' ', '')}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </WagerItem>
              ))}
            </div>
          ))
        )}
      </div>
    </TableWithScroll>
  );
}
