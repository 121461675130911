import { Api } from 'common/api';
import { Route, Routes } from 'common/routes';
import { setPersistentCookie } from 'common/utils';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useLayoutEffect,
} from 'react';
import {
  Auth,
  KycStatus,
  login,
  LoginCredentials,
  Profile,
  UserStatus,
} from 'services/auth/login';
import { NewUserRegistration, register } from 'services/auth/register';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import {
  deleteAppsFlyer,
  deleteAppsFlyerCookies,
  setAppsFlyerUserEvent,
} from 'services/_af-events/appsflyer';
import { useAuthInterceptor } from '../../hooks/use-auth-interceptor/use-auth-interceptor';

export type AuthContext = {
  signIn?: (credentials: LoginCredentials) => Promise<void>;
  signOut?: (to?: Route, shouldLogout?: boolean) => Promise<void>;
  signUp?: (form: NewUserRegistration) => Promise<void>;
  profile?: Profile;
  auth?: Auth;
  setAuth?: Dispatch<SetStateAction<Auth>>;
  token?: string;
  updateStatus?: (state: UserStatus) => void;
  updateKyc?: (state: KycStatus) => void;
  updateAuthAndToken?(auth: Auth): void;
};

const defaultAuthState = {
  status: undefined,
  session: undefined,
  token: undefined,
  auth: undefined,
  kyc: undefined,
};

export const AuthContext = React.createContext<AuthContext>({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = (props) => {
  // @ts-ignore
  const sessionStorageAuth = sessionStorage.getItem('auth');
  const isSessionStorage = ENABLE_NEXT_AUTH === 'false' && sessionStorageAuth;
  const [auth, setAuth] = React.useState<Auth>(
    isSessionStorage ? JSON.parse(sessionStorageAuth) : defaultAuthState,
  );

  useLayoutEffect(() => {
    if (!auth.token) {
      deleteAppsFlyerCookies();
      Api.defaultHeaders.delete('Authorization');
      return;
    }
    if (
      FeatureFlagsService?.featureFlags?.salesforceLiveAgent ||
      FeatureFlagsService?.featureFlags?.salesforceTestPlayerIds?.includes(
        auth.session?.playerId.toString(),
      )
    ) {
      window.Bspot.auth = auth;
      initSalesforceChat(auth);
    }

    setApiAuthorization(auth.token);
  }, [auth.token, FeatureFlagsService?.featureFlags]);

  const initSalesforceChat = (auth) => {
    window['embedded_svc'].settings.prepopulatedPrechatFields = {
      FirstName: auth?.session.firstName,
      LastName: auth?.session.lastName,
      Email: auth?.session.email,
      WebEmail: auth?.session.email,
      SuppliedEmail: auth?.session.email,
      Origin: 'Liberty Web',
      Subject:
        'New live chat ' +
        auth?.session.firstName +
        ' ' +
        auth?.session.lastName,
    };
    // window.initESW('https://service.force.com');
  };

  const DestroySalesforceChat = () => {
    window['embedded_svc'].settings.prepopulatedPrechatFields = {
      FirstName: null,
      LastName: null,
      Email: null,
      WebEmail: null,
      SuppliedEmail: null,
      Subject: null,
    };
    (window as any)?.initESW('https://service.force.com');
  };

  const updateAuthAndToken = (auth) => {
    setAuth(auth);
    if (FeatureFlagsService?.featureFlags?.salesforceLiveAgent) {
      window.Bspot.auth = auth;
      initSalesforceChat(auth);
    }
    setApiAuthorization(auth.token);
    setSessionStorageAuth(auth);
    setExtremePushPlayerId(auth);
    setAppsFlyerUserEvent(auth?.session?.playerId.toString());
    // initDatadog(auth);
  };

  const signOut = React.useCallback(async (to = Routes.Lobby) => {
    setAuth(defaultAuthState);
    setExtremePushPlayerId(defaultAuthState);
    Api.defaultHeaders.delete('Authorization');
    sessionStorage.removeItem('auth');
    localStorage.removeItem('xtremepush.data');
    deleteAppsFlyer();
    window.Bspot.auth = null;
    if (FeatureFlagsService.featureFlags.salesforceLiveAgent) {
      DestroySalesforceChat();
    }
    setTimeout(() => {
      window.location.replace(Routes.Lobby.path);
    }, 1500);
  }, []);

  const signUp = async (form) => {
    const auth = await register(form);
    if (process.env.NODE_ENV === 'development') {
      console.log('AUTH : ' + JSON.stringify(auth));
    }
    setPersistentCookie(`${auth.session.playerId}isRegistered`, true, 30);
    updateAuthAndToken(auth);
  };

  const signIn = async (credentials: LoginCredentials) => {
    const auth = await login(credentials);
    if (process.env.NODE_ENV === 'development') {
      console.log('AUTH : ' + JSON.stringify(auth));
    }
    updateAuthAndToken(auth);
  };

  const setApiAuthorization = (token) => {
    Api.defaultHeaders.set('Authorization', `Bearer ${token}`);
  };

  const setSessionStorageAuth = (auth) => {
    if (ENABLE_NEXT_AUTH === 'false')
      sessionStorage.setItem('auth', JSON.stringify(auth));
  };

  const setExtremePushPlayerId = (auth) => {
    if (!window['xtremepush']) return;
    window['xtremepush']('set', 'user_id', auth.session?.playerId || '');
  };

  useAuthInterceptor(auth, signOut);

  // const initDatadog = (auth) => {
  //   // datadogRum.setUser({
  //   //   id: auth.session.playerId.toString(),
  //   //   name: auth.session.firstName + ' ' + auth.session.lastName,
  //   //   session: auth.session.sessionId,
  //   });
  // };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        auth,
        setAuth,
        profile: auth?.session,
        token: auth?.token,
        updateAuthAndToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
